import 'intersection-observer'
import '../styles/app.global.scss'
import { store } from '@/store/store'
import { Provider } from 'react-redux'
import { AppProps } from 'next/app'

import AppProvider from 'context/AppContext'
// import { useServiceWorker } from '@/utils/useServiceWorker'

function MyApp({ Component, pageProps }: AppProps) {
  // useServiceWorker()
  return (
    <Provider store={store}>
      <AppProvider>
        <Component {...pageProps} />
      </AppProvider>
    </Provider>
  )
}

export default MyApp

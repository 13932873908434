import { createContext, useContext, useMemo, FC } from 'react'
import PropTypes from 'prop-types'

export const AppContext = createContext(null)
type Props = {
  children: JSX.Element | JSX.Element[]
}
const AppProvider: FC<Props> = ({ children }) => {
  const value = useMemo(() => ({}), [])
  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

AppProvider.propTypes = {
  children: PropTypes.any,
}

export const useAppContext = () => useContext(AppContext)

export default AppProvider

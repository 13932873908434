import { createSlice, Slice } from '@reduxjs/toolkit'

interface IUIState {
  open: boolean
}

const initialState: IUIState = {
  open: false,
}

const modalSlice: Slice<typeof initialState> = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    toggleOpen: (state, { payload }: { payload: boolean }) => {
      state.open = payload
    },
  },
})

export default modalSlice

import { createSlice, Slice } from '@reduxjs/toolkit'

interface IUIState {
  headerAppear: boolean
}

const initialState: IUIState = {
  headerAppear: false,
}

const globalSlice: Slice<typeof initialState> = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setHeaderAppear: (state, { payload }: { payload: boolean }) => {
      state.headerAppear = payload
    },
  },
})

export default globalSlice

import { createSlice, Slice } from '@reduxjs/toolkit'

interface IUIState {
  hover: boolean
  posH: number
  modalRequest: boolean
  modalTry: boolean
  replayFlash: number
  inputValue: string
  targetable: boolean
}

const initialState: IUIState = {
  hover: false,
  posH: null,
  modalRequest: false,
  modalTry: false,
  replayFlash: 0,
  inputValue: '',
  targetable: false,
}

const launcherSlice: Slice<typeof initialState> = createSlice({
  name: 'launcher',
  initialState,
  reducers: {
    setHover: (state, { payload }: { payload: boolean }) => {
      state.hover = payload
    },
    setPosH: (state, { payload }: { payload: number }) => {
      state.posH = payload
    },
    toggleModalRequest: (state, { payload }: { payload: boolean }) => {
      state.modalRequest = payload
    },
    toggleModalTry: (state, { payload }: { payload: boolean }) => {
      state.modalTry = payload
    },
    toggleReplayFlash: (state, {}) => {
      state.replayFlash += 1
    },
    setInputValue: (state, { payload }: { payload: string }) => {
      state.inputValue = payload
    },
    setTargetable: (state, { payload }: { payload: boolean }) => {
      state.targetable = payload
    },
    clearAll: (state, {}) => {
      state.hover = false
      state.posH = null
      state.modalRequest = false
      state.modalTry = false
      state.replayFlash = 0
      state.inputValue = ''
      state.targetable = false
    },
  },
})

export default launcherSlice

import { configureStore } from '@reduxjs/toolkit'
import spriteCanvasSlice from './reducers/spriteCanvas'
import globalSlice from './reducers/global'
import launcherSlice from './reducers/launcher'
import modalSlice from './reducers/modal'
import introSlice from './reducers/intro'

export const store = configureStore({
  reducer: {
    global: globalSlice.reducer,
    launcher: launcherSlice.reducer,
    spriteCanvas: spriteCanvasSlice.reducer,
    modal: modalSlice.reducer,
    intro: introSlice.reducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

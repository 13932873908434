import { createSlice, Slice } from '@reduxjs/toolkit'

interface IUIState {
  appear: boolean
  ready: boolean
}

const initialState: IUIState = {
  appear: false,
  ready: false,
}

const introSlice: Slice<typeof initialState> = createSlice({
  name: 'intro',
  initialState,
  reducers: {
    setAppear: (state, { payload }: { payload: boolean }) => {
      state.appear = payload
    },
    setReady: (state, { payload }: { payload: boolean }) => {
      state.ready = payload
    },
  },
})

export default introSlice

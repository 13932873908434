import { createSlice, Slice } from '@reduxjs/toolkit'

interface IUIState {
  frame: number
  frameCount: number
  width: number
  height: number
  url: string
}

const initialState: IUIState = {
  frame: 1,
  frameCount: 170,
  width: 0,
  height: 0,
  url: '',
}

const spriteCanvasSlice: Slice<typeof initialState> = createSlice({
  name: 'spriteCanvas',
  initialState,
  reducers: {
    setFrame: (state, { payload }: { payload: number }) => {
      state.frame = payload
    },
    setDimensions: (state, { payload }: { payload: any }) => {
      state.width = payload.width
      state.height = payload.height
    },
    setFrameCount: (state, { payload }: { payload: number }) => {
      state.frameCount = payload
    },
    setURL: (state, { payload }: { payload: string }) => {
      state.url = payload
    },
  },
})

export default spriteCanvasSlice
